import { weekDays } from 'modules/group/pages/Group/sections/Schedule/form/const';

const formatNumber = (value = '', delimiter = ',') => {
  if (!value) {
    return value;
  }

  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};

const reformatNumber = (value = '', delimiter = ',') => {
  if (!value) {
    return value;
  }

  return String(value).replaceAll(delimiter, '');
};

const getFullName = (user) => {
  // if (!user?.firstName && !user?.lastName) {
  //   return 'имя не указано';
  // }

  return `${user.firstName || ''} ${user.lastName || ''}`.trim();
};

const sortByWeekDay = (array) => {
  if (Array.isArray(array) && array.length > 0) {
    return array.sort(
      (a, b) => weekDays.indexOf(a.day) - weekDays.indexOf(b.day),
    );
  }

  return array;
};

const sortIncludingWeekDay = ({ array, name }) => {
  if (Array.isArray(array) && array.length > 0) {
    return array.sort((a, b) => {
      if (a[name] === b[name]) {
        return weekDays.indexOf(a.day) - weekDays.indexOf(b.day);
      }

      return a[name].localeCompare(b[name]);
    });
  }

  return array;
};

const sortIncludingWeekDayLabel = ({ array, name, delimiter = '(' }) => {
  if (Array.isArray(array) && array.length > 0) {
    return array.sort((a, b) => {
      const [aFirst, aSecond] = a[name].split(delimiter);
      const [bFirst, bSecond] = b[name].split(delimiter);

      if (aFirst === bFirst && aSecond && bSecond) {
        return (
          weekDays.indexOf(aSecond.slice(0, -1)) -
          weekDays.indexOf(bSecond.slice(0, -1))
        );
      }

      return aFirst.localeCompare(bFirst);
    });
  }

  return array;
};

const normalizeCssSize = (value) => {
  if (!value) {
    return '100%';
  }

  if (!isNaN(value)) {
    return `${value}px`;
  }

  if (value === 'auto' || value.includes('px') || value.includes('%')) {
    return value;
  }

  return '100%';
};

const getPlural = (number, values = []) => {
  let n = number % 100;

  if (n >= 11 && n <= 19) {
    return values[2];
  } else {
    n = number % 10;

    switch (n) {
      case 1:
        return values[0];
      case 2:
      case 3:
      case 4:
        return values[1];
      default:
        return values[2];
    }
  }
};

const isBoolValue = (value) => [true, false].includes(value);

export {
  getPlural,
  formatNumber,
  reformatNumber,
  getFullName,
  sortByWeekDay,
  sortIncludingWeekDay,
  sortIncludingWeekDayLabel,
  normalizeCssSize,
  isBoolValue,
};
