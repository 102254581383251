import { Radio, Flex } from '@mantine/core';
import { useFormContext } from './context';

export const yesNoOptions = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' },
];

export const RadioGroupField = ({
  name,
  label,
  items,
  inline = false,
  radioStyles,
  isVisible = true,
  ...rest
}) => {
  const form = useFormContext();

  if (!isVisible || !items?.length) {
    return null;
  }

  const radiGroupProps = form.getInputProps(name);

  let value = radiGroupProps.value;

  if (value === 'true') {
    value = true;
  } else if (value === 'false') {
    value = false;
  }

  return (
    <Radio.Group label={label} {...radiGroupProps} value={value} {...rest}>
      <Flex
        direction={inline ? 'row' : 'column'}
        gap={10}
        mt={15}
        style={radioStyles}
      >
        {items.map((item) => {
          return (
            <Radio key={item.value} value={item.value} label={item.label} />
          );
        })}
      </Flex>
    </Radio.Group>
  );
};
