import { FormProvider, useForm } from 'components/ui/form';
import { BaseForm } from './BaseForm';
import { getInitialFormState } from './schema';
import { useUpdateMeeting } from 'modules/group/hooks/use-meeting-mutation';
import { formatDateToISOString } from 'utils/date';
import { showSuccess } from 'core/notifications';
import { DrawerFactory } from 'core/providers/DrawerProvider';
import { Button } from '@mantine/core';
import { useStyles } from './MeetingForm.styles';
import { MEETING_STORAGE_KEY } from './use-before-unload';

export const EditForm = ({ meeting }) => {
  const { classes } = useStyles();

  const initialFormState = getInitialFormState({
    isAnnouncementsDone: 'isAnnouncementsDone' in meeting,
    isLiteratureShown: 'isLiteratureShown' in meeting,
  });

  const form = useForm({
    ...initialFormState,
    initialValues: {
      ...meeting,
      moneyLiterature: meeting.moneyLiterature || 0,
      moneyTransfer: meeting.moneyTransfer || 0,
      moneyRent: meeting.moneyRent || 0,
      date: new Date(meeting.date),
    },
  });
  const canSubmit = form.isDirty();

  const updateMeetingMutation = useUpdateMeeting();

  const submitHandler = () => {
    localStorage.removeItem(MEETING_STORAGE_KEY);

    const meetingData = {
      ...form.values,
      date: formatDateToISOString(form.values?.date),
    };

    const { hasErrors } = form.validate();

    if (!hasErrors) {
      updateMeetingMutation.mutate(meetingData, {
        onSuccess: () => {
          showSuccess({ title: 'Данные сохранены' });
          form.reset();
          DrawerFactory.close();
        },
      });
    }
  };

  return (
    <FormProvider form={form}>
      <BaseForm onSubmit={submitHandler} />

      <Button
        mt={20}
        onClick={submitHandler}
        disabled={!canSubmit}
        className={classes.submit}
      >
        Отправить
      </Button>
    </FormProvider>
  );
};
