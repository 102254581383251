import { object, string, number, date, boolean } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';

export const groupSchema = object({
  title: string().required(messages.required),
  country: string().required(messages.required),
  city: string().required(messages.required),
  address: string(),
  phone: string(),
  geoLocation: string(),
  birthDate: date().nullable(),
  currency: string(),
  createdAt: string(),
  updatedAt: string(),
  telegramChatId: string(),
  storeEndingLimit: number()
    .transform((c, o) => (o === '' ? null : c))
    .required(messages.required)
    .min(1),
  jobEndingLimit: number()
    .transform((c, o) => (o === '' ? null : c))
    .required(messages.required)
    .min(7),
  meetingFormOptionalFields: object({
    teaMan: boolean(),
    cleaner: boolean(),
    guests: boolean(),
    before30days: boolean(),
    anniversaries: boolean(),
    isAnnouncementsDone: boolean(),
    isLiteratureShown: boolean(),
  }),
  reportDefaultPeriod: string(),
});

export const initialFormState = {
  initialValues: {
    title: '',
    country: '',
    city: '',
    address: '',
    phone: '',
    geoLocation: '',
    birthDate: null,
    currency: '',
    createdAt: '',
    updatedAt: '',
    telegramChatId: '',
    storeEndingLimit: 1,
    jobEndingLimit: 7,
    meetingFormOptionalFields: {
      teaMan: false,
      cleaner: false,
      guests: true,
      before30days: true,
      anniversaries: true,
      isAnnouncementsDone: false,
      isLiteratureShown: false,
    },
    reportDefaultPeriod: 'lastMonth',
  },
  validate: yupResolver(groupSchema),
  validateInputOnBlur: true,
};
