import { array, object, string, date, boolean } from 'yup';
import { yupResolver } from '@mantine/form';
import { messages } from 'utils/messages';
import { isBoolValue } from 'utils/string';

const meetingBaseFields = {
  date: date().required(messages.required),
  leader: string().required(messages.required),
  teaMan: string(),
  cleaner: string(),
  money: string().required(messages.required),
  moneyLiterature: string(),
  moneyTransfer: string(),
  moneyRent: string(),
  newcomers: string(),
  guests: string(),
  before30days: string(),
  anniversaries: string(),
  participants: string().required(messages.required),
  comment: string(),
  released: array().of(object()).nullable(),
};

const meetingOptionalFields = {
  isAnnouncementsDone: boolean().required(messages.requiredOption),
  isLiteratureShown: boolean().required(messages.requiredOption),
};

export const getMeetingSchema = (excludedFields = []) => {
  const schema = {
    ...meetingBaseFields,
    ...meetingOptionalFields,
  };

  excludedFields.forEach((field) => {
    delete schema[field];
  });

  return object(schema);
};

const getInitialFormlValues = (excludedFields = []) => {
  const values = {
    date: new Date(),
    leader: '',
    teaMan: '',
    cleaner: '',
    money: '',
    moneyLiterature: '',
    moneyTransfer: '',
    moneyRent: '',
    newcomers: '',
    guests: '',
    before30days: '',
    anniversaries: '',
    participants: '',
    comment: '',
    released: [],
    isAnnouncementsDone: null,
    isLiteratureShown: null,
  };

  excludedFields.forEach((field) => {
    delete values[field];
  });

  return values;
};

export const getInitialFormState = ({
  isAnnouncementsDone,
  isLiteratureShown,
}) => {
  const excludedFields = [];

  if (!isAnnouncementsDone) {
    excludedFields.push('isAnnouncementsDone');
  }

  if (!isLiteratureShown) {
    excludedFields.push('isLiteratureShown');
  }

  return {
    initialValues: getInitialFormlValues(excludedFields),
    validate: yupResolver(getMeetingSchema(excludedFields)),
    validateInputOnBlur: true,
  };
};
