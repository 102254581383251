import { Container, Group, Title, Button } from '@mantine/core';
import { useStyles } from './MeetingForm.styles';
import { useAddMeeting } from 'modules/group/hooks/use-meeting-mutation';
import { showError, showSuccess } from 'core/notifications';
import { getInitialFormState } from './schema';
import { FormProvider, useForm } from 'components/ui/form';
import { formatDateToISOString } from 'utils/date';
import { BaseForm } from './BaseForm';
import { useGroupId } from 'modules/group/hooks/use-group-query';
import { useEffect } from 'react';
import { MEETING_STORAGE_KEY } from './use-before-unload';
import { useNavigate } from 'react-router-dom';
import { useGroup } from 'modules/group/context';

export const MeetingForm = () => {
  const groupId = useGroupId();

  const { meetingFormOptionalFields: optionalFields = {} } = useGroup();

  const { isAnnouncementsDone, isLiteratureShown } = optionalFields;

  const { classes, cx } = useStyles();
  const form = useForm(
    getInitialFormState({ isAnnouncementsDone, isLiteratureShown }),
  );
  const canSubmit = form.isDirty();

  const navigate = useNavigate();

  const addMeetingMutation = useAddMeeting();

  useEffect(() => {
    // trying to get data from local storage (if it was saved before when user left page without saving)
    const storageMeetingData = localStorage.getItem(MEETING_STORAGE_KEY);

    if (storageMeetingData) {
      form.setValues(JSON.parse(storageMeetingData));
    }
  }, []);

  const submitHandler = () => {
    localStorage.removeItem(MEETING_STORAGE_KEY);

    const meetingData = {
      groupId,
      ...form.values,
      date: formatDateToISOString(form.values?.date),
    };

    const { hasErrors } = form.validate();

    if (!hasErrors) {
      addMeetingMutation.mutate(meetingData, {
        onSuccess: () => {
          showSuccess({ title: 'Спасибо!', message: 'Данные сохранены' });
          form.reset();
          navigate(`/groups/${groupId}/meetings/summary`);
        },
        onError: ({ response }) => {
          if (response.status === 422) {
            showError({
              title: 'Ошибка!',
              message: response.data?.message,
              autoClose: 15000,
            });
          }
        },
      });
    }
  };

  return (
    <FormProvider form={form}>
      <Container className={classes.container}>
        <div className={classes.formWrapper}>
          <Group spacing={8} position="center" className={classes.titleGroup}>
            <Title className={classes.title}>Результаты</Title>
            <Title className={cx(classes.title, classes.titleSecond)}>
              собрания
            </Title>
          </Group>

          <BaseForm />

          <Button
            className={classes.submit}
            mt={20}
            onClick={submitHandler}
            disabled={!canSubmit}
          >
            Отправить
          </Button>
        </div>
      </Container>
    </FormProvider>
  );
};
