import {
  useFormContext,
  TextField,
  DatePickerField,
  NumberField,
  SelectField,
  CheckboxField,
  RadioGroupField,
} from 'components/ui/form';
import {
  Button,
  Stack,
  useMantineTheme,
  Text,
  Divider,
  Group,
} from '@mantine/core';
import { IconCalendarFilled } from '@tabler/icons-react';
import { countryOptions } from 'core/const';
import { whiteDark, getBorderColor } from 'core/theme/utils';
import { createCityOptions } from 'utils/create-city-options';

const Checkbox = ({ label, name }) => (
  <CheckboxField
    name={`meetingFormOptionalFields.${name}`}
    label={label}
    mt={0}
    mb={0}
  />
);

const SectionTitle = ({ title }) => (
  <>
    <Divider mb={20} mt={30} />

    <Text fz={14} color="dimmed" mb={10}>
      {title}
    </Text>
  </>
);

export const BaseForm = ({ onSubmit }) => {
  const theme = useMantineTheme();
  const form = useFormContext();
  const canSubmit = form.isValid() && form.isDirty();

  const countryValue = form.values.country;

  const cityOptions = createCityOptions(countryValue);

  return (
    <>
      <Stack spacing={5} mb={20} p={16}>
        <Text fz={14} color="dimmed" mb={10}>
          Общее
        </Text>
        <TextField label="Название" name="title" required />
        <SelectField
          name="country"
          label="Страна"
          data={countryOptions}
          required
          onChange={() => {
            form.setFieldValue('city', '');
          }}
        />

        <SelectField name="city" label="Город" required data={cityOptions} />

        <TextField label="Адрес" name="address" />
        <TextField
          label="Геолокация"
          name="geoLocation"
          placeholder="41.305576,69.295960"
          description="Координаты для геолокации можно посмотреть в Яндекс.картах, используя точный адрес группы"
        />
        <TextField label="Телефон" name="phone" />
        <TextField label="Валюта" name="currency" />
        <DatePickerField
          clearable
          icon={<IconCalendarFilled size="1rem" />}
          maxDate={new Date()}
          valueFormat="DD.MM.YYYY"
          label="День рождения"
          placeholder="DD.MM.YYYY"
          name="birthDate"
        />

        <SectionTitle title="Склад (Литком)" />

        <NumberField
          label="Минимальное количество товара на складе"
          description="Индикатор для отображения заканчивающегося товара на складе (литком)"
          name="storeEndingLimit"
          step={1}
        />

        <SectionTitle title="Служения" />

        <NumberField
          label="Количество дней до окончания служения"
          description="Индикатор для отображения заканчивающегося служения"
          name="jobEndingLimit"
          step={1}
        />

        <SectionTitle title="Форма ведущего (опциональные поля)" />

        <div>
          <Text fz={12} color="">
            Выберите поля, которые дополнительно хотите добавить в форму
            ведущего
          </Text>

          <Group spacing={15} mt={12}>
            <Checkbox name="teaMan" label="Чайханщик" />
            <Checkbox name="cleaner" label="Уборщик" />
            <Checkbox name="guests" label="Юбиляры" />
            <Checkbox name="before30days" label="Гости" />
            <Checkbox name="anniversaries" label="До 30 дней" />
            <Checkbox name="moneyRent" label="Аренда помещения" />
            <Checkbox name="isAnnouncementsDone" label="Обявления сделаны" />
            <Checkbox name="isLiteratureShown" label="Литература выставлена" />
          </Group>
        </div>

        <SectionTitle title="Статистика" />

        <RadioGroupField
          name="reportDefaultPeriod"
          label="Укажите период, который будет использоваться по умолчанию для отображения статистики"
          items={[
            { value: 'lastMonth', label: 'Последний месяц' },
            { value: 'lastDay', label: 'Последний день' },
          ]}
        />

        <SectionTitle title="Дополнительно" />

        <TextField
          label="Телеграм чат id"
          name="telegramChatId"
          description={`Идентификатор вашей группы или канала в телеграме. Для использования функций чат бота необходимо добавить бот "@NaPortal" в ваш телеграм канал или группу. Бот не имеет администаративных прав, не читает ваши сообщения. Только отправляет сообщения, связанные с вашей группой, с сайта в телеграм группу.`}
        />
      </Stack>

      <div
        style={{
          position: 'sticky',
          bottom: 0,
          padding: 16,
          borderTop: `1px solid ${getBorderColor(theme)}`,
          backgroundColor: whiteDark(theme),
          zIndex: theme.other.zIndex.drawer,
          boxShadow: '0px -2px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Button
          fullWidth
          size="md"
          onClick={() => {
            const { hasErrors } = form.validate();

            if (!hasErrors) {
              onSubmit(form.values);
            }
          }}
          disabled={!canSubmit}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
};
